export class Vendor {
    VendorId: number;
    VendorName: string;
    VendorCode: string;
    VendorLogo: string;
    IsTrial: Boolean = false;
    TrialDuration: number;
    GSTIN: string;
    BusinessEmail: string;
    BusinessMobile: string;
    Active: Boolean;
    CreatedTime: Date;
    UpdatedTime: Date;
    RequiredWholesale: Boolean = false;
    AllowPriceEdit: Boolean = false;
    BusinessType: number = 1;
    SMSEnabled: boolean;
    EmailEnabled: boolean;
    RequiredStaffNameInBill: boolean;
    CRMEnabled: boolean = false;
    BBLCEnabled: boolean = false;
    GreetingSMSEnabled: boolean = false;
    TransactionalSMS: number = 0;
    PromotionalSMS: number = 0;
    BirthDayGreeting: boolean = false;
    AnniversaryGreeting: boolean = false;
    SenderId: string;
    VendorWebsite: string;
    IsOnlyCRM: boolean;
    EnableConsumableRequest: boolean = false;
    RequiredCalcMode: boolean = true;
    IsDailyReportEnabled: boolean = false;
    RequiredLocationSMSConfig: boolean = false;
    CurrencySymbol: string = "Rs";
}